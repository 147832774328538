import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('identity', ['loggedInAsRep']),
  },
  methods: {
    getReadabilityClass() {
      return this.loggedInAsRep ? 'cursor-not-allowed' : ''
    },
  },
}
